* {
  margin: 0px auto;
  padding: 0px;
  outline: none;
  list-style-type: none;
  box-sizing: border-box;
}
body {
  width: 100%;
  font-family: "Roboto", sans-serif;
  background-color: #fff;
}
a {
  text-decoration: none !important;
}
button {
  cursor: pointer;
}
/*-------------------------General CSS start here-------------------------*/
.gnrl_red_btn {
  border: 0px;
  color: #fff;
  padding: 6px 20px;
  border-radius: 20px;
  background: linear-gradient(to right, #000 50%, #ff0000 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  font-size: 16px;
  z-index: 999;
}
.gnrl_red_btn img {
  margin: -4px 0 0 8px;
}
.gnrl_red_btn:hover {
  background-position: left bottom;
}
.gnrl_red_btn:focus {
  outline: none !important;
}
.gnrl_black_brder_btn {
  border: 1px #000 solid;
  color: #000;
  padding: 6px 18px;
  border-radius: 20px;
  background: linear-gradient(to right, #dbdbdb 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  z-index: 999;
}
.gnrl_black_brder_btn img {
  margin: -4px 0 0 8px;
}
.gnrl_black_brder_btn:hover {
  background-position: left bottom;
}
.gnrl_black_brder_btn:focus {
  outline: none;
}

.extra_small_txt {
  float: left;
  width: 100%;
  font-size: 12px;
  color: #646464;
  margin: 0px;
  z-index: 999;
}
.mediam_txt {
  float: left;
  width: 100%;
  font-size: 18px;
  color: #646464;
  margin: 0px;
  z-index: 999;
}
.page_title {
  float: left;
  width: 100%;
  font-size: 55px;
  color: #000;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  margin: 0px;
  line-height: 63px;
  z-index: 999;
  word-wrap: break-word;
}
.page_title span {
  color: #ff0000;
}
.section_title {
  float: left;
  width: 100%;
  font-size: 30px;
  color: #000;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  margin: 0px;
  z-index: 999;
}
.section_title span {
  color: #ff0000;
}
.gnrl_para {
  float: left;
  width: 100%;
  font-size: 15px;
  color: #646464;
  line-height: 22px;
  font-weight: 300;
  z-index: 999;
}
.gnrl_para span {
  color: #000;
  font-weight: bold;
}

/*---------------------------------------------- header css start ----------------------------------------------*/
header {
  float: left;
  width: 100%;
  background-color: transparent;
  padding: 19px 0;
}
header .main_logo img {
  float: left;
  width: 75%;
  margin: 8px 0 0 0;
}
header nav {
  float: left;
  width: 100%;
}
header nav ul {
  float: right;
  margin: 0px;
}
header nav ul li {
  float: left;
  position: relative;
}
header nav ul li a {
  float: left;
  color: #000;
  text-decoration: none;
  letter-spacing: 1px;
  font-size: 14px;
  padding: 7px 10px;
}
/*.desktop_menu a:hover{color: #000 !important;}*/

.drop_down {
  float: left;
  width: 250px;
  position: absolute;
  top: 33px;
  left: -13px;
  z-index: 999;
  background-color: #fff;
  box-shadow: 9px 9px 21px 0px rgba(0, 0, 0, 0.2);
  padding: 10px 0px;
  border-radius: 10px;
  display: none;
}
.drop_down li {
  float: left;
  width: 100%;
  padding: 15px 25px;
}
.drop_down .hver_01:hover {
  background-color: #f4dccc;
  transition: all 0.5s ease;
}
.drop_down .hver_02:hover {
  background-color: #c3d8f6;
  transition: all 0.5s ease;
}
.drop_down .hver_03:hover {
  background-color: #e6ddf4;
  transition: all 0.5s ease;
}
.drop_down .hver_04:hover {
  background-color: #d1d3f6;
  transition: all 0.5s ease;
}
.drop_down .hver_05:hover {
  background-color: #f6d6d6;
  transition: all 0.5s ease;
}
.drop_down .hver_06:hover {
  background-color: #bae4f4;
  transition: all 0.5s ease;
}
.drop_down .hver_07:hover {
  background-color: #ebcad9;
  transition: all 0.5s ease;
}
.drop_down .hver_08:hover {
  background-color: #c4e4ee;
  transition: all 0.5s ease;
}

.drop_down li a:hover {
  text-decoration: none;
  color: #000;
}
.drop_down li a {
  color: #000;
  float: left;
  width: 100%;
  padding: 0px;
  text-decoration: none;
}
ul .custm_drop:hover > ul {
  display: block;
  transition: all 0.5s ease;
}

.mobile_menu_btn {
  display: none;
}
.scnd_footer {
  float: left;
  width: 100%;
  background-color: #0b222c;
}
.scnd_footer p {
  color: #fff;
  margin: 0px;
  padding: 20px 0;
  letter-spacing: 1px;
}
.menu_drop_main {
  float: left;
  width: 100%;
  border-bottom: 1px solid #fff6;
  padding: 0 0 25px 0;
}
.menu_drop_main a {
  font-size: 20px !important;
}
.mobile_menu .gnrl_red_btn {
  margin-bottom: 25px;
  margin-top: 10px;
}

.overlay {
  height: 100%;
  width: 92%;
  position: fixed;
  z-index: 9999 !important;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.9);
  overflow-x: hidden;
  transition: 0.5s !important;
  /* display: none; */
}
.overlay-content {
  position: relative;
  top: 10%;
  width: 100%;
  text-align: center;
  margin-top: 30px;
}
.overlay a {
  padding: 8px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}
.overlay a:hover,
.overlay a:focus {
  color: #f1f1f1;
}
.overlay .closebtn {
  position: absolute;
  top: 20px;
  right: 45px;
  font-size: 60px;
}
@media screen and (max-height: 450px) {
  .overlay a {
    font-size: 20px;
  }
  .overlay .closebtn {
    font-size: 40px;
    top: 15px;
    right: 35px;
  }
}

/*---------------------------------------------- header css end ----------------------------------------------*/

/*-------------------------------------home page CSS start here-------------------------------------*/
.home_sec_01 {
  float: left;
  width: 100%;
}
.home_sec_01_left {
  float: left;
  width: 100%;
  position: relative;
}
.home_sec_01_left .doted_squere {
  position: absolute;
  right: 130px;
  top: 80px;
  z-index: -10;
}
.home_sec_01_left .red_leaf {
  position: absolute;
  left: -75px;
  top: 243px;
  z-index: -10;
  width: 40px;
}
.home_sec_01_right {
  float: left;
  width: 100%;
}
.home_sec_01_right img {
  width: 100%;
  margin: 20px 0 0 0;
}

.home_sec_02 {
  float: left;
  width: 100%;
  position: relative;
  background-color: #fff6f6;
}
.sec_curve_img {
  position: absolute;
  width: 100%;
  top: 0px;
  left: 0px;
}
.home_sec_02 .section_title {
  margin: 130px 0 0 0;
}
.home_sec_02_left img {
  width: 100%;
}
.home_sec_02_right {
  float: left;
  width: 100%;
}
.home_sec_02_right ul {
  float: left;
  width: 100%;
}
.home_sec_02_right ul li {
  float: left;
  width: 100%;
  padding: 10px 0;
}
.home_sec_02_right ul li img {
  float: left;
  width: 23px;
}
.home_sec_02_right ul li p {
  float: left;
  font-weight: 300;
  font-size: 15px;
  color: #646464;
  padding: 0 0 0 10px;
  width: calc(100% - 25px);
  margin: 0;
}

.home_sec_03 {
  float: left;
  width: 100%;
}
.home_sec_03_mob_img {
  float: left;
  width: 100%;
  position: relative;
}
.home_sec_03_mob_img .doted_squere {
  position: absolute;
  width: 26%;
  bottom: 5px;
  left: 29px;
  z-index: -2;
}
.home_sec_03_mob_img img {
  width: 80%;
  margin: 0px auto;
  display: table;
}
.home_sec_03_right {
  float: left;
  width: 100%;
  text-align: left;
}
.home_sec_03_right .mediam_txt {
  color: #000;
  font-weight: bold;
}
.home_sec_03_right .mediam_txt_btm_line {
  float: left;
  width: 30px;
  height: 4px;
  background-color: #000;
  margin: 5px 0 0 0;
}
.home_sec_03_right .tech_icon {
  float: left;
}
#carousel .carousel-item {
  width: 100%;
  min-height: 350px;
  background: no-repeat center center scroll;
  background-size: cover;
}
.carousel-indicators li {
  background-color: #dedede !important;
  height: 15px;
  width: 15px;
  border-radius: 50%;
}
.carousel-indicators .active {
  background-color: #ff0000 !important;
}
#carousel .carousel-item .caption {
  padding: 40px;
  color: white;
  animation-duration: 1s;
  animation-delay: 2s;
}
#carousel .caption h2 {
  animation-duration: 1s;
  animation-delay: 2s;
}
#carousel .caption p {
  animation-duration: 1s;
  animation-delay: 2.2s;
}
#carousel .caption a {
  animation-duration: 1s;
  animation-delay: 2.4s;
}
.delicious-btn {
  display: inline-block;
  min-width: 160px;
  height: 60px;
  color: #ffffff;
  border: none;
  border-left: 3px solid #1c8314;
  border-radius: 0;
  padding: 0 30px;
  font-size: 16px;
  line-height: 58px;
  font-weight: 600;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms;
  text-transform: capitalize;
  background-color: #40ba37;
}
.delicious-btn.active,
.delicious-btn:hover,
.delicious-btn:focus {
  font-size: 16px;
  font-weight: 600;
  color: #ffffff;
  background-color: #1c8314;
  border-color: #40ba37;
}

.home_sec_04 {
  float: left;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0;
}
.home_sec_04 img {
  max-width: 125px;
}

.home_sec_05 {
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.home_sec_05 .doted_squere {
  position: absolute;
  bottom: 0px;
  right: -30px;
  z-index: -2;
}
.home_sec_05 .mediam_txt {
  color: #000;
}
.home_sec_05 span img {
  max-width: 40px;
}
.home_sec_05 .develpo_pro_icon1:hover {
  animation: border 0.4s ease 1 forwards;
}

.home_sec_05 .develpo_pro_icon1 {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: 1px solid #6056de;
  display: table;
  padding: 16px 0 0 0;
}
.home_sec_05 .develpo_pro_icon2 {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: 1px solid #ff9400;
  display: table;
  padding: 17px 0 0 0;
}
.home_sec_05 .develpo_pro_icon3 {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: 1px solid #4bd965;
  display: table;
  padding: 19px 0 0 0;
}
.home_sec_05 .develpo_pro_icon4 {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: 1px solid #c64bd9;
  display: table;
  padding: 20px 0 0 0;
}
.home_sec_05 .develpo_pro_icon5 {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: 1px solid #007afe;
  display: table;
  padding: 14px 0 0 0;
}
.home_sec_05 .develpo_pro_icon6 {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: 1px solid #fedc00;
  display: table;
  padding: 17px 0 0 0;
}

.home_sec_06 {
  float: left;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
  overflow: hidden;
}
.home_sec_06 .slide .mediam_txt {
  color: #000;
}
.home_sec_06 .service_card_01 {
  background-color: #f4dccc;
}
.home_sec_06 .service_card_01:hover {
  background-color: #f5caae;
  transition: 0.3s ease;
  box-shadow: 2px 3px 26px 0px rgba(0, 0, 0, 0.18);
}
.home_sec_06 .service_card_02 {
  background-color: #c3d8f6;
}
.home_sec_06 .service_card_02:hover {
  background-color: #aacaf8;
  transition: 0.3s ease;
  box-shadow: 2px 3px 26px 0px rgba(0, 0, 0, 0.18);
}
.home_sec_06 .service_card_03 {
  background-color: #e6ddf4;
}
.home_sec_06 .service_card_03:hover {
  background-color: #dac8f6;
  transition: 0.3s ease;
  box-shadow: 2px 3px 26px 0px rgba(0, 0, 0, 0.18);
}
.home_sec_06 .service_card_04 {
  background-color: #d1d3f6;
}
.home_sec_06 .service_card_04:hover {
  background-color: #b6baf5;
  transition: 0.3s ease;
  box-shadow: 2px 3px 26px 0px rgba(0, 0, 0, 0.18);
}
.home_sec_06 .service_card_05 {
  background-color: #f6d6d6;
}
.home_sec_06 .service_card_05:hover {
  background-color: #f6bdbd;
  transition: 0.3s ease;
  box-shadow: 2px 3px 26px 0px rgba(0, 0, 0, 0.18);
}
.home_sec_06 .service_card_06 {
  background-color: #bae4f4;
}
.home_sec_06 .service_card_06:hover {
  background-color: #97dcf6;
  transition: 0.3s ease;
  box-shadow: 2px 3px 26px 0px rgba(0, 0, 0, 0.18);
}
.home_sec_06 .service_card_07 {
  background-color: #ebcad9;
}
.home_sec_06 .service_card_07:hover {
  background-color: #ebb4cd;
  transition: 0.3s ease;
  box-shadow: 2px 3px 26px 0px rgba(0, 0, 0, 0.18);
}
.home_sec_06 .service_card_08 {
  background-color: #c4e4ee;
}
.home_sec_06 .service_card_08:hover {
  background-color: #aadeee;
  transition: 0.3s ease;
  box-shadow: 2px 3px 26px 0px rgba(0, 0, 0, 0.18);
}
.home_sec_06 .service_card_img {
  height: 170px;
  float: left;
  padding-top: 20px;
  margin: 10px 0 0 0;
}
.home_sec_06 .service_card_img img {
  width: 80%;
}
.slide {
  text-align: center;
  margin: 0px 10px !important;
  padding: 15px;
  border-radius: 10px;
}
/* Slider */
.slick-slide {
  margin: 0px 20px;
}
.slick-slide img {
  width: 100%;
}
.slick-slider {
  float: left;
  width: 100%;
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

.home_sec_07 {
  float: left;
  width: 100%;
  position: relative;
}
.home_sec_07_left img {
  width: 95%;
}
.home_sec_07_right img {
  width: 95%;
}
.home_sec_07 .doted_squere {
  position: absolute;
  bottom: 20px;
  left: 10px;
  z-index: -1;
}

.get_free_quot_sec {
  float: left;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}
.get_free_quot_sec .section_title {
  font-size: 20px;
}

.home_sec_08 {
  float: left;
  width: 100%;
}
.home_sec_08 .gallery-title {
  font-size: 36px;
  color: #42b32f;
  text-align: center;
  font-weight: 500;
  margin-bottom: 70px;
}
.home_sec_08 .gallery-title:after {
  content: "";
  position: absolute;
  width: 7.5%;
  left: 46.5%;
  height: 45px;
  border-bottom: 1px solid #5e5e5e;
}
.home_sec_08 .filter-button {
  font-size: 16px;
  border: 0px;
  text-align: center;
  color: #000;
  background-color: transparent;
  padding: 0 15px;
}
.home_sec_08 .filter-button:hover {
  color: red;
}
.home_sec_08 .filter-button:focus {
  border: 0px !important;
  outline: none;
  color: red !important;
}
.home_sec_08 .btn-default:active .filter-button:active {
  background-color: #42b32f;
  color: white;
}
.home_sec_08 .port-image {
  width: 100%;
}
.home_sec_08 .gallery_product {
  margin-bottom: 30px;
}
.portfolio_card_main {
  background-color: #f9f9f9;
  width: 100%;
  float: left;
  border-radius: 10px;
}
.portfolio_card_main .gnrl_para {
  height: 170px;
}
.portfolio_card_main .mediam_txt {
  color: #000;
  font-weight: bold;
}
.portfolio_card_main .mediam_txt_btm_line {
  float: left;
  width: 30px;
  height: 4px;
  background-color: #000;
  margin: 5px 0 0 0;
}
.portfolio_card_main .portfolio_card_right img {
  width: 95%;
  margin: 84px 0 0 0;
  float: left;
}
.portfolio_card_main .custm_lef-pad {
  padding-left: 45px;
}
.gallery_product .gnrl_black_brder_btn {
  float: left;
}

.home_sec_09 {
  float: left;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0;
}

.blog_main {
  float: left;
  width: 100%;
  position: relative;
}
.blog_main_box {
  float: left;
  width: 100%;
  background-color: #f9f9f9;
  border-radius: 10px;
  overflow: hidden;
}
.blog_main_box_img {
  width: 100%;
  float: left;
  height: 160px;
  overflow: hidden;
}
.blog_main_box_img img {
  width: 100%;
}
.blog_main_box_txt {
  width: 100%;
  float: left;
  padding: 20px;
}
.blog_main_box_txt .mediam_txt {
  color: #000;
}
.blog_btn {
  float: right;
  color: #000;
  border: 0px;
  background-color: transparent;
}
.blog_btn img {
  margin: -4px 0 0 0;
}
.blog_main .doted_squere {
  position: absolute;
  bottom: -31px;
  left: 120px;
  z-index: -1;
}
.blog_main .doted_squere2 {
  position: absolute;
  top: 25px;
  right: 0px;
  z-index: -1;
}

footer {
  float: left;
  width: 100%;
  background-color: #343434;
  position: relative;
}
.footer_01 .footer_01_logo {
  width: 100%;
  float: left;
}
.footer_01 .footer_01_logo img {
  width: 100%;
}
.footer_01 .mail_title {
  font-size: 14px;
  color: #c2c2c2;
  float: left;
  margin: 20px 0 0 0;
  width: 100%;
}
.footer_01 .mail_id {
  font-size: 16px;
  color: #fff;
  float: left;
  width: 100%;
}
.footer_01 .footer_soccial_icon {
  width: 100%;
  float: left;
}
.footer_01 .footer_soccial_icon a {
  float: left;
  width: 16.6%;
  text-align: center;
}
.footer_01 .footer_soccial_icon a img {
  width: 65%;
  float: none;
  margin: 0px auto;
  display: table;
}
.fiiter_custm_pading {
  padding-left: 60px;
}
.footer_02 p {
  float: left;
  width: 100%;
  color: #fff;
  font-weight: bold;
  margin: 0 0 0 0;
}
.footer_02 ul {
  float: left;
  width: 100%;
  margin: 0px;
}
.footer_02 ul li {
  float: left;
  width: 100%;
}
.footer_02 ul li a {
  float: left;
  width: 100%;
  color: #fff;
  padding: 4px 0px;
  font-size: 14px;
}
.footer_03 p {
  float: left;
  width: 100%;
  color: #fff;
  font-weight: bold;
  margin: 0 0 0 0;
}
.footer_03 input {
  float: left;
  width: 100%;
  border: 1px solid #807e7e;
  background: transparent;
  padding: 7px 14px;
  color: #fff;
  border-radius: 5px;
  margin: 20px 0 0 0;
}
.footer_03 button {
  float: left;
  width: 100%;
  color: #343434;
  border-radius: 5px;
  padding: 7px 14px;
  border: 0px;
  margin: 20px 0 0 0;
}
footer .sec_curve_img {
  position: absolute;
  top: -1px;
  left: 0px;
}
.fiiter_custm_margin {
  margin: 175px 0 0 0;
}
.footer_04 p {
  width: 100%;
  float: left;
  text-align: center;
  color: #fff;
  font-size: 12px;
  margin: 11px 0 0 auto;
  padding: 15px 0;
  border-top: 1px solid #444;
}

/*-------------------------------------home page CSS end-------------------------------------*/

/*-------------------------------------about us page CSS end-------------------------------------*/

.about_sec01 {
  width: 100%;
  float: left;
  position: relative;
}
.about_sec01 img {
  width: 50%;
}
.about_sec01 .doted_squere {
  position: absolute;
  right: 397px;
  top: 390px;
  z-index: -99;
  width: 100px;
  z-index: -2;
}
.about_sec02 {
  float: left;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}
.about_sec02_img {
  float: left;
  width: 100%;
}
.about_sec02_img img {
  max-height: 250px;
  border-radius: 10px;
  z-index: 1;
  position: relative;
}
.about_sec02 .mediam_txt {
  font-weight: bold;
  color: #000;
}
.about_sec02_img .doted_squere {
  position: absolute;
  left: 50px;
  z-index: 0 !important;
  top: -40px;
  border-radius: 0;
}

.about_sec02 .carousel-inner .gnrl_para {
  padding: 0 90px 0 0;
}

.about_sec03 {
  float: left;
  width: 100%;
}
.about_sec03 img {
  width: 40%;
  padding: 20px 0 0 0;
}

.about_sec04 {
  float: left;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}

.about_sec05 {
  float: left;
  width: 100%;
  overflow: hidden;
}
/*.about_sec05 .gnrl_red_btn img{width:unset; }
.about_sec05 ul {margin: 0px auto; width: 87%; list-style: none; display: table; float: none; }
.about_sec05 li {width: 14vw; height: 12vw; -webkit-clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0); clip-path: polygon(75% 0, 100% 50%, 75% 100%, 25% 100%, 0 50%, 25% 0); float: left; margin-right: 10vw; margin-left: -42px;}
.about_sec05 li:nth-child(2n) {margin: -6vw -5vw 0 -13.5vw;}
.about_sec05 img {width: 100%; -o-object-fit: cover; object-fit: cover; -o-object-position: 50% 50%; object-position: 50% 50%; }
.about_sec05 main{float: left; width: 100%; margin: 90px 0 0 0;}*/
.defilee__main {
  position: relative;
  margin: 0 auto;
}
.defilee__outer {
  width: 100%;
  height: 75vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  perspective: 0.5px;
  transform-style: preserve-3d;
  overflow: hidden;
  background-image: url("../img/team_BG.svg");
  background-size: cover;
}

.defilee__ctnr {
  transform-style: preserve-3d;
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
}

.defilee__div {
  position: relative;
  transform: translateZ(-0.1px) scale(1.4) translateX(30px) translateY(5vh);
}

.defilee__outer .doted_squere {
  position: absolute;
  width: 100px !important;
}

.defilee__div:not(.defilee__div--text) {
  width: 15vw;
  height: 15vw;
  margin: 0px;
  padding: 0px !important;
  background: white;
  padding: 0.75vw;
  border-radius: 50%;
  overflow: hidden;
  box-shadow: 0 10px 40px -10px rgba(0, 0, 0, 0.2);
}
.defilee__div--text {
  margin: 0 0 0 10vw;
  transform: translateZ(-0.25px) scale(1.4) translateX(60px) translateY(-5vh);
  animation: zoom infinite 1s;
}

.defilee__div--special {
  transform: translateZ(-0.2px) scale(1.4) translateX(30px) translateY(20vh);
}
.defilee__div--special1 {
  transform: translateZ(-0.25px) scale(1.4) translateX(-0.3vw) translateY(0);
}

.defilee__div--special2 {
  transform: translateZ(-0.3px) scale(1.5) translateX(-0.5vw) translateY(-5vh);
}
.defilee__div--special3 {
  transform: translateZ(-0.5px) scale(1.5) translateX(-30px) translateY(-30vh);
}
.defilee__div--special4 {
  transform: translateZ(-0.65px) scale(3) translateX(30px) translateY(-10vh);
}
.defilee__ctnr {
  transform: translate3d(-10%, 0, 0);
  will-change: transform;
  animation: slide 25s 0.7s linear infinite;
}
@keyframes slide {
  100% {
    transform: translate3d(-60%, 0, 0);
  }
}
.defilee__div--special1.first {
  animation: vertical infinite 5s -2s;
}
@keyframes vertical {
  50% {
    transform: translateZ(-0.25px) scale(1.6) translateX(-0.3vw)
      translateY(-5vh);
  }
}

@keyframes zoom {
  50% {
    transform: translateZ(-0.25px) scale(1.5) translateX(60px) translateY(-5vh);
  }
}

.about_sec05 .defilee__outer img {
  width: 100%;
  margin-bottom: 0.75vw;
  /*filter: sepia(100%) hue-rotate(180deg);*/
}
div:nth-child(3n + 1) > img {
  /*filter: sepia(100%) hue-rotate(-83deg);*/
}

.about_sec06 {
  float: left;
  width: 100%;
}
.about_sec06 .cutm_pading {
  padding: 0px 0px;
}
.gallery {
  position: relative;
  z-index: 2;
  padding: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-flow: row wrap;
  flex-flow: row wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.gallery.pop {
  -webkit-filter: blur(10px);
  filter: blur(10px);
}
.gallery figure {
  cursor: pointer;
  width: 100%;
  float: left;
  overflow: hidden;
  margin: 0px;
  padding: 4px;
}
.gallery figure img {
  width: 100%;
  /* border-radius: 10px; */
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.gallery figure figcaption {
  display: none;
}
.popup {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.75);
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in-out 0.2s;
  transition: opacity 0.5s ease-in-out 0.2s;
}
.popup.pop {
  opacity: 1;
  -webkit-transition: opacity 0.2s ease-in-out 0s;
  transition: opacity 0.2s ease-in-out 0s;
}
.popup.pop figure {
  margin-top: 0;
  opacity: 1;
}
.popup figure {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  margin-top: 30px;
  opacity: 0;
  -webkit-animation: poppy 500ms linear both;
  animation: poppy 500ms linear both;
}
.popup figure img {
  position: relative;
  z-index: 2;
  border-radius: 15px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2), 0 6px 30px rgba(0, 0, 0, 0.4);
}
.popup figure figcaption {
  position: absolute;
  bottom: 50px;
  background: -webkit-linear-gradient(top, transparent, rgba(0, 0, 0, 0.78));
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.78));
  z-index: 2;
  width: 100%;
  border-radius: 0 0 15px 15px;
  padding: 100px 20px 20px 20px;
  color: #fff;
  font-family: "Open Sans", sans-serif;
  font-size: 32px;
}
.popup figure figcaption small {
  font-size: 11px;
  display: block;
  text-transform: uppercase;
  margin-top: 12px;
  text-indent: 3px;
  opacity: 0.7;
  letter-spacing: 1px;
}
.popup figure .shadow {
  position: relative;
  z-index: 1;
  top: -15px;
  margin: 0 auto;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 98%;
  height: 50px;
  opacity: 0.6;
  -webkit-filter: blur(15px) contrast(2);
  filter: blur(15px) contrast(2);
}
.popup .close {
  position: absolute;
  z-index: 3;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  cursor: pointer;
  background: url(#close);
  border-radius: 25px;
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
.popup .close svg {
  width: 100%;
  height: 100%;
}

.cutm_wid_25 {
  float: left;
  width: 25%;
}
.cutm_wid_50 {
  float: left;
  width: 50%;
}

/*------------------------------------------ Contact Us page CSS start here -----------------------------------------*/

.contact_us_01 {
  float: left;
  width: 100%;
}
.contact_us_01 img {
  width: 100%;
}
.contact_us_01 .section_title {
  margin: 140px 0 0 0 !important;
}

.contact_us_02 {
  float: left;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 1) 25%,
    rgba(249, 249, 249, 1) 25%,
    rgba(249, 249, 249, 1) 100%
  );
}
.contact_us_form {
  float: left;
  width: 100%;
  border-radius: 15px;
  background-color: #fff;
  padding: 40px;
  box-shadow: 4px 6px 18px 0px rgba(0, 0, 0, 0.09);
}
.contact_us_form input {
  float: left;
  width: 100%;
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #969696;
  font-size: 14px;
  padding: 15px 0px;
}
.contact_us_form textarea {
  float: left;
  width: 100%;
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #969696;
  font-size: 14px;
  padding: 15px 0px;
  height: 52px;
}
.contact_us_form_btm.mt-5 {
  padding-left: 50px;
}
.contact_us_form_btm .country {
  float: left;
  width: 100%;
  font-weight: bold;
  font-size: 30px;
  color: #ff0000;
  font-family: "Quicksand", sans-serif;
}
.contact_us_form_btm .city {
  float: left;
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: #000;
}
.contact_us_form_btm .mail_phote {
  float: left;
  width: 100%;
  font-size: 15px;
  color: #5897e8;
  border-bottom: 1px solid #969696;
  padding: 0 0 13px 0;
}
.contact_us_form_btm .gnrl_para {
  font-size: 14px;
}

/*-------------------------------------- Career page CSS start here --------------------------------------*/

.career_01 {
  float: left;
  width: 100%;
}
.career_01 img {
  width: 100%;
}
.career_01 .section_title {
  margin: 130px 0 0 0 !important;
}

.career_02 {
  float: left;
  width: 100%;
  background-color: #f9f9f9;
  padding: 50px 0px;
}
.career_02 .doted_squere {
  position: absolute;
  bottom: 0px;
  right: 10px;
}
.career_02 .mediam_txt {
  color: #000;
}
.career_02 span img {
  max-width: 40px;
}
.career_02 .develpo_pro_icon1 {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: 1px solid #6056de;
  display: table;
  padding: 16px 0 0 0;
}
.career_02 .develpo_pro_icon2 {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: 1px solid #ff9400;
  display: table;
  padding: 22px 0 0 0;
}
.career_02 .develpo_pro_icon3 {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  border: 1px solid #4bd965;
  display: table;
  padding: 13px 0 0 0;
}

.career_03 {
  float: left;
  width: 100%;
}

.career_04 {
  float: left;
  width: 100%;
  background-color: #fff6f6;
  padding: 50px 0px;
}
.career_04 .section_title {
  margin: 90px 0 0 0;
}
.career_04 img {
  width: 80%;
}
.career_04 .gnrl_para a {
  color: #ff0000;
}

.career_05 {
  float: left;
  width: 100%;
}
.career_05 .card-header {
  float: left;
  width: 100%;
}
.career_05 .accordion > .card {
  border: 0px;
}
.career_05 .accordion > .card > .card-header {
  margin-bottom: 2px !important;
  background-color: #fff;
  border: 1px solid #c5c5c5;
}
.career_05 .accordion > .card > .card-body {
  border: 1px solid #c5c5c5;
  border-top: 0px;
}
.career_05 .accordion .card .card-body-title {
  color: #000;
  font-size: 20px;
  margin: 0px;
}
.career_05 .accordion > .card > .card-body ul {
  float: left;
  width: 100%;
  padding: 0 0 0 4px;
}
.career_05 .accordion > .card > .card-body ul li {
  margin: 10px 0 0 0;
  float: left;
  width: 100%;
  list-style-type: none;
  color: #969696;
  font-size: 15px;
  font-weight: 300;
  position: relative;
  padding: 0 0 0 10px;
}
.career_05 .accordion > .card > .card-body ul li::before {
  content: "";
  height: 7px;
  width: 7px;
  background-color: #000;
  position: absolute;
  left: -4px;
  top: 8px;
  border-radius: 50%;
}
.career_05 .accordion > .card > .card-body ul li .card-title {
  font-size: 20px;
  float: left;
  color: #000;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  margin-bottom: 0px;
}
.card-title span {
  font-size: 15px;
}
.accordion .card-header::after {
  content: "";
  background-image: url("../img/minus-circle.svg");
  background-size: 100%;
  height: 20px;
  width: 20px;
  float: right;
  margin: 4px 0 0 0;
}
.accordion .card-header.collapsed::after {
  content: "";
  background-image: url("../img/plus-circle.svg") !important;
  background-size: 100%;
  height: 20px;
  width: 20px;
  float: right;
  margin: 4px 0 0 0;
}

.apply_for_job {
  float: left;
  width: 100%;
}
.apply_for_job input {
  float: left;
  width: 100%;
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #969696;
  font-size: 14px;
  padding: 15px 0px;
}
.apply_for_job textarea {
  float: left;
  width: 100%;
  background-color: transparent;
  border: 0px;
  border-bottom: 1px solid #969696;
  font-size: 14px;
  padding: 15px 0px;
}

/*-------------------------------------- Event page CSS start here --------------------------------------*/

.event_01 {
  float: left;
  width: 100%;
}
.event_01 img {
  width: 100%;
}
.event_01 .section_title {
  margin: 80px 0 0 0 !important;
}

.event_02 {
  float: left;
  width: 100%;
}
.event_02_left_img {
  float: left;
  width: 100%;
  max-height: 480px;
  overflow: hidden;
  border-radius: 0 35px 0 0;
}
.event_02_left_img img {
  float: left;
  width: 100%;
}
.event_02_right_img {
  float: left;
  width: 100%;
  max-height: 480px;
  overflow: hidden;
  border-radius: 35px 0 0 0;
}
.event_02_right_img img {
  float: left;
  width: 100%;
}

.event_02_left_content {
  float: left;
  width: 100%;
}
.event_02_left_content .section_title {
  margin-top: 108px !important;
}
.event_02_right_content {
  float: left;
  width: 100%;
  text-align: right;
}
.event_02_right_content .section_title {
  margin-top: 108px !important;
}

/*-------------------------------------- Testimonial page CSS start here --------------------------------------*/

.testimonial_01 {
  float: left;
  width: 100%;
}
.testimonial_01 img {
  width: 100%;
}
.testimonial_01 .section_title {
  margin: 100px 0 0 0 !important;
}

.testimonial_02 {
  float: left;
  width: 100%;
  position: relative;
  background-color: #f9f9f9;
  padding: 50px 0px;
}
.testimonial_02 .sec_curve_img {
  position: absolute;
  top: 0px;
  left: 0px;
}
.testimonial_02 .section_title {
  margin: 120px 0 0 0;
}

.testimonial_02_video {
  float: left;
  width: 100%;
  height: 250px;
  border-radius: 10px;
  overflow: hidden;
}
.testimonial_02_video_title {
  float: left;
  width: 100%;
  color: #000;
  font-size: 20px;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
  margin: 0px;
}

.drop_shedow_01 {
  box-shadow: 9px 9px 0px 0px rgb(244, 220, 204);
}
.drop_shedow_02 {
  box-shadow: 9px 9px 0px 0px rgb(195, 216, 246);
}
.drop_shedow_03 {
  box-shadow: 9px 9px 0px 0px rgb(230, 221, 244);
}
.drop_shedow_04 {
  box-shadow: 9px 9px 0px 0px rgb(209, 211, 246);
}
.drop_shedow_05 {
  box-shadow: 9px 9px 0px 0px rgb(246, 214, 214);
}
.drop_shedow_06 {
  box-shadow: 9px 9px 0px 0px rgb(186, 228, 244);
}

.testimonial_03 {
  float: left;
  width: 100%;
}
.testimonial_03_profile {
  float: left;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  overflow: hidden;
}
.testimonial_03_main {
  position: relative;
}
.testimonial_03_main .doted_squere {
  position: absolute;
  left: -13px;
  top: -12px;
  z-index: -1;
  border-radius: 50%;
}
.testimonial_03_profile img {
  width: 100%;
  filter: grayscale(100%);
}
.testimonial_03 .testimonial_03_title {
  float: left;
  width: 100%;
  text-align: left;
  margin: 10px 0 0 0;
  font-family: "Quicksand", sans-serif;
  font-weight: bold;
}
.testimonial_03 .gnrl_para {
  float: left;
  width: 100%;
  text-align: left;
}

/*-------------------------------------- Blog page CSS start here --------------------------------------*/

.blog_01 {
  float: left;
  width: 100%;
}
.blog_01 img {
  width: 100%;
}
.blog_01 .section_title {
  margin: 80px 0 0 0 !important;
}

/*-------------------------------------- Blog detais page CSS start here --------------------------------------*/

.blog_details_01 {
  float: left;
  width: 100%;
}
.blog_details_01_img {
  float: left;
  width: 100%;
}
.blog_details_01_img img {
  width: 100%;
  max-height: 80%;
}

/*-------------------------------------- services page CSS start here --------------------------------------*/

.service_header {
  float: left;
  width: 100%;
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(249, 249, 249, 1) 100%
  );
}
.service_header img {
  width: 75%;
  margin: 60px 0 0 0;
}
.service_header .section_title {
  margin: 130px 0 0 0;
}
.service_details_txt ul {
  float: left;
  width: 100%;
  padding: 0 0 0 4px;
}
.service_details_txt ul li {
  float: left;
  width: 100%;
  list-style-type: none;
  color: #646464;
  font-weight: 300;
  position: relative;
  padding: 0 0 0 10px;
}
.service_details_txt ul li::before {
  content: "";
  height: 7px;
  width: 7px;
  background-color: #000;
  position: absolute;
  left: -4px;
  top: 8px;
  border-radius: 50%;
}

.service_header_arrow {
  float: left;
  width: 100%;
  position: relative;
  height: 120px;
}
.service_header_arrow img {
  width: 31.3%;
  position: absolute;
  top: 0px;
  left: 130px;
}
.service_details_main {
  float: left;
  width: 100%;
}

.service_details_img_right img {
  width: 100%;
  border-radius: 30px 0 0 0;
}
.service_details_img_left img {
  width: 100%;
  border-radius: 0 30px 0 0;
}

/*-------------------------------------- Project details CSS start here --------------------------------------*/

.pro_details_01 {
  float: left;
  width: 100%;
}
.pro_details_01_img {
  float: left;
  width: 100%;
  background-color: #f9f9f9;
  padding: 40px;
}
.pro_details_01_img img {
  width: 80%;
  margin: 0px auto;
  display: table;
}
.pro_details_01_technology {
  float: left;
}
.pro_details_01_technology img {
  margin: 0 0 0 15px;
}
.pro_details_01_technology .gnrl_para {
  color: #000;
  font-weight: bold;
}
.pro_details_01 .mediam_txt {
  color: #000;
}
.carousel-control-prev-icon {
  background-image: url("../img/our_pro_Previous.svg") !important;
  width: 37px;
}
.carousel-control-next-icon {
  background-image: url("../img/our_pro_Next.svg") !important;
  width: 37px;
}
.slide1 {
  opacity: 1;
  width: 100% !important;
  transform: translate3d(0px, 0px, 0px);
}
.slide2 {
  width: 100% !important;
}
.carousel-indicators {
  bottom: -30px !important;
}
.slick-track {
  position: relative;
  height: fit-content !important;
  top: 0;
  width: 6000px !important;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.imgView {
  background-color: #007afe !important;
}
.gallery figure .imgView {
  float: left;
  width: 80%;
  border-radius: 10px;
}
.modal-header .close:focus {
  outline: none !important;
}

.home_sec_09 .carousel .img {
  width: 100%;
  float: left;
  position: relative;
}
.home_sec_09 .carousel .img img {
  display: block;
  width: 150px;
  height: 150px;
  margin: auto;
  border-radius: 50%;
  z-index: 1;
}
.home_sec_09 .carousel .img .doted_squere {
  position: absolute;
  top: 0;
  left: 39%;
  z-index: -1 !important;
  width: 100px;
  height: 100px;
}
.cursor {
  cursor: pointer;
}

/* .carousel-item {
  transition: none !important;
} */
