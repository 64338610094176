.testim {
  width: 100%;
  float: left;
}
.testim .wrap {
  position: relative;
  width: 100%;
  max-width: 1020px;
  padding: 15px 20px;
  margin: auto;
}
.testim .arrow {
  display: block;
  position: absolute;
  color: #eee;
  cursor: pointer;
  font-size: 2em;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  padding: 5px;
  z-index: 22222222;
}
.testim .arrow:before {
  cursor: pointer;
}
.testim .arrow:hover {
  color: #ea830e;
}
.testim .arrow.left {
  left: -70px;
}
.testim .arrow.right {
  right: -70px;
}
.testim .dots {
  text-align: center; /*position: absolute;*/
  width: 100%; /*bottom: 60px;*/ /*left: 0;*/
  display: block;
  z-index: 3333;
  height: 12px;
}
.testim .dots .dot {
  list-style-type: none;
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  border: 1px solid #575555;
  margin: 0 10px;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  position: relative;
}
.testim .dots .dot.active,
.testim .dots .dot:hover {
  background: #ff0000;
  border-color: #ff0000;
}
.testim .dots .dot.active {
  -webkit-animation: testim-scale 0.5s ease-in-out forwards;
  -moz-animation: testim-scale 0.5s ease-in-out forwards;
  -ms-animation: testim-scale 0.5s ease-in-out forwards;
  -o-animation: testim-scale 0.5s ease-in-out forwards;
  animation: testim-scale 0.5s ease-in-out forwards;
}
.testim .cont {
  position: relative;
  overflow: hidden;
}
.testim .cont > div {
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 0 70px 0;
  opacity: 0;
}
.testim .cont > div.inactive {
  opacity: 1;
}
.testim .cont > div.active {
  position: relative;
  opacity: 1;
}
.testim .cont div .img img {
  display: block;
  width: 150px;
  height: 150px;
  margin: auto;
  border-radius: 50%;
  z-index: 1;
}
.testim .cont div.active .img img {
  -webkit-animation: testim-show 0.5s ease-in-out forwards;
  -moz-animation: testim-show 0.5s ease-in-out forwards;
  -ms-animation: testim-show 0.5s ease-in-out forwards;
  -o-animation: testim-show 0.5s ease-in-out forwards;
  animation: testim-show 0.5s ease-in-out forwards;
}
.testim .cont div.active h2 {
  -webkit-animation: testim-content-in 0.4s ease-in-out forwards;
  -moz-animation: testim-content-in 0.4s ease-in-out forwards;
  -ms-animation: testim-content-in 0.4s ease-in-out forwards;
  -o-animation: testim-content-in 0.4s ease-in-out forwards;
  animation: testim-content-in 0.4s ease-in-out forwards;
}
.testim .cont div.active p {
  -webkit-animation: testim-content-in 0.5s ease-in-out forwards;
  -moz-animation: testim-content-in 0.5s ease-in-out forwards;
  -ms-animation: testim-content-in 0.5s ease-in-out forwards;
  -o-animation: testim-content-in 0.5s ease-in-out forwards;
  animation: testim-content-in 0.5s ease-in-out forwards;
}
.testim .cont div.inactive .img img {
  -webkit-animation: testim-hide 0.5s ease-in-out forwards;
  -moz-animation: testim-hide 0.5s ease-in-out forwards;
  -ms-animation: testim-hide 0.5s ease-in-out forwards;
  -o-animation: testim-hide 0.5s ease-in-out forwards;
  animation: testim-hide 0.5s ease-in-out forwards;
}
.testim .cont div.inactive h2 {
  -webkit-animation: testim-content-out 0.4s ease-in-out forwards;
  -moz-animation: testim-content-out 0.4s ease-in-out forwards;
  -ms-animation: testim-content-out 0.4s ease-in-out forwards;
  -o-animation: testim-content-out 0.4s ease-in-out forwards;
  animation: testim-content-out 0.4s ease-in-out forwards;
}
.testim .cont div.inactive p {
  -webkit-animation: testim-content-out 0.5s ease-in-out forwards;
  -moz-animation: testim-content-out 0.5s ease-in-out forwards;
  -ms-animation: testim-content-out 0.5s ease-in-out forwards;
  -o-animation: testim-content-out 0.5s ease-in-out forwards;
  animation: testim-content-out 0.5s ease-in-out forwards;
}

.testim .cont div .img {
  position: relative;
}
.testim .cont div .img .doted_squere {
  position: absolute;
  top: 0;
  left: 39%;
  z-index: 0 !important;
  width: 100px;
  height: 100px;
}

@-webkit-keyframes testim-scale {
  0% {
    -webkit-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -webkit-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -webkit-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -webkit-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-moz-keyframes testim-scale {
  0% {
    -moz-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -moz-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -moz-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -moz-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-ms-keyframes testim-scale {
  0% {
    -ms-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -ms-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -ms-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -ms-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-o-keyframes testim-scale {
  0% {
    -o-box-shadow: 0px 0px 0px 0px #eee;
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    -o-box-shadow: 0px 0px 10px 5px #eee;
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    -o-box-shadow: 0px 0px 10px 5px #ea830e;
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    -o-box-shadow: 0px 0px 0px 0px #ea830e;
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@keyframes testim-scale {
  0% {
    box-shadow: 0px 0px 0px 0px #eee;
  }

  35% {
    box-shadow: 0px 0px 10px 5px #eee;
  }

  70% {
    box-shadow: 0px 0px 10px 5px #ea830e;
  }

  100% {
    box-shadow: 0px 0px 0px 0px #ea830e;
  }
}

@-webkit-keyframes testim-content-in {
  from {
    opacity: 0;
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes testim-content-in {
  from {
    opacity: 0;
    -moz-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes testim-content-in {
  from {
    opacity: 0;
    -ms-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-o-keyframes testim-content-in {
  from {
    opacity: 0;
    -o-transform: translateY(100%);
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes testim-content-in {
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@-webkit-keyframes testim-content-out {
  from {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-moz-keyframes testim-content-out {
  from {
    opacity: 1;
    -moz-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -moz-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-ms-keyframes testim-content-out {
  from {
    opacity: 1;
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    -ms-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@-o-keyframes testim-content-out {
  from {
    opacity: 1;
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes testim-content-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

@-webkit-keyframes testim-show {
  from {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-moz-keyframes testim-show {
  from {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }
}

@-ms-keyframes testim-show {
  from {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }
}

@-o-keyframes testim-show {
  from {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }

  to {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes testim-show {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@-webkit-keyframes testim-hide {
  from {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-moz-keyframes testim-hide {
  from {
    opacity: 1;
    -moz-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -moz-transform: scale(0);
    transform: scale(0);
  }
}

@-ms-keyframes testim-hide {
  from {
    opacity: 1;
    -ms-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -ms-transform: scale(0);
    transform: scale(0);
  }
}

@-o-keyframes testim-hide {
  from {
    opacity: 1;
    -o-transform: scale(1);
    transform: scale(1);
  }

  to {
    opacity: 0;
    -o-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes testim-hide {
  from {
    opacity: 1;
    transform: scale(1);
  }

  to {
    opacity: 0;
    transform: scale(0);
  }
}

@media all and (max-width: 300px) {
  body {
    font-size: 14px;
  }
}

@media all and (max-width: 500px) {
  .testim .arrow {
    font-size: 1.5em;
  }

  .testim .cont div p {
    line-height: 25px;
  }
}
