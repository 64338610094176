@media only screen and (max-width: 1200px) {
  .portfolio_card_main .custm_lef-pad {
    padding-left: 45px;
  }
  .career_04 .section_title {
    margin: 60px 0 0 0;
  }
  .event_02_left_content .section_title {
    margin-top: 95px !important;
  }
  .testimonial_01 .section_title {
    margin-top: 95px !important;
  }
  .event_02_right_content .section_title {
    margin-top: 95px !important;
  }
}
@media only screen and (max-width: 1038px) {
  .defilee__div:not(.defilee__div--text) {
    width: 20vw;
    height: 20vw;
  }
}

@media only screen and (max-width: 991px) {
  .mobile_menu_btn {
    display: block !important;
  }
  .desktop_menu {
    display: none;
  }
  header .main_logo {
    text-align: center;
  }
  header .main_logo img {
    width: 275px;
    float: none;
    padding: 0 0 20px 0;
  }
  .fourth_sec_main_img img {
    margin-top: 20px;
  }
  .get_free_quot_sec .section_title {
    text-align: center;
    margin-bottom: 20px;
  }
  .get_free_quot_sec .gnrl_red_btn {
    margin: 0px auto;
    display: table;
  }
  .contact_us_01 .section_title {
    margin: 20px 0 0 0 !important;
  }
  .career_01 .section_title {
    margin: 20px 0 0 0 !important;
  }
  .event_01 .section_title {
    margin: 20px 0 0 0 !important;
  }
  .blog_01 .section_title {
    margin: 20px 0 0 0 !important;
  }
  .career_04 .section_title {
    margin: 35px 0 0 0;
  }
  .event_02_left_content .section_title {
    margin-top: 35px !important;
  }
  .testimonial_01 .section_title {
    margin-top: 35px !important;
  }
  .event_02_right_content .section_title {
    margin-top: 35px !important;
  }
  lottie-player {
    height: 320px !important;
    width: 320px !important;
  }
  .popup.pop figure {
    float: left;
    width: 100%;
  }
  .popup figure img {
    width: 100%;
  }
}

@media only screen and (max-width: 828px) {
  .testim .arrow.left {
    left: -10px !important;
  }
  .testim .arrow.right {
    right: -10px !important;
  }
}

@media only screen and (max-width: 768px) {
  .footer_03 {
    margin: 30px 0 0 0 !important;
  }
  .blog_main_box_img {
    height: 280px;
  }
  .event_02_left_content .section_title {
    margin-top: 0px !important;
  }
  .testimonial_01 .section_title {
    margin-top: 0px !important;
  }
  .event_02_right_content .section_title {
    margin-top: 0px !important;
  }
  .service_header .section_title {
    margin: 0px auto;
    display: table;
    padding: 20px 0;
    text-align: center;
  }
  .service_header_arrow img {
    width: 70%;
    left: 40px;
  }
  .home_sec_01_left .red_leaf {
    left: -35px;
  }
  .about_sec02 .carousel-inner .gnrl_para {
    padding: 0 0 0 0;
  }
  .defilee__div:not(.defilee__div--text) {
    width: 25vw;
    height: 25vw;
  }
  .cutm_wid_25 {
    float: left;
    width: 50%;
  }
  .cutm_wid_50 {
    float: left;
    width: 100%;
  }
  .testim .cont div .img .doted_squere {
    top: 5px !important;
    left: 26% !important;
  }
  .overlay .closebtn {
    font-size: 40px;
  }
  .service_details_main .mt-5 {
    margin-top: 15px !important;
  }
  .service_details_main .mb-5 {
    margin-bottom: 15px !important;
  }
}
@media only screen and (max-width: 576px) {
  .fiiter_custm_margin {
    margin: 30px 0 0 0 !important;
  }
  .footer_01_logo {
    margin: 30px 0 0 0;
  }
  .footer_01_logo img {
    width: 80% !important;
    margin: 0px auto;
    display: table;
  }
  .page_title {
    font-size: 50px;
  }
  .portfolio_card_main .gnrl_para {
    height: 120px;
  }
  .portfolio_card_main .gnrl_black_brder_btn {
    margin: 0px !important;
    float: left !important;
  }
  .portfolio_card_main .portfolio_card_right img {
    margin-top: 0px;
  }
  .contact_us_01 .section_title {
    margin: 0 0 0 0 !important;
  }
  .career_01 .section_title {
    margin: 0 0 0 0 !important;
  }
  .event_01 .section_title {
    margin: 0 0 0 0 !important;
  }
  .blog_01 .section_title {
    margin: 0 0 0 0 !important;
  }
  .contact_us_form_btm.mt-5 {
    padding-left: 15px;
  }
  .event_02_right_content {
    text-align: left;
    margin: 0 0 20px 0;
  }
  .pro_details_01_img {
    padding: 20px;
  }
  .defilee__div:not(.defilee__div--text) {
    width: 30vw;
    height: 30vw;
  }
  .fiiter_custm_pading {
    padding-left: 30px;
  }
  .service_header img {
    width: 100%;
    margin: 10px 0 0 0;
  }
  lottie-player {
    height: 300px !important;
    width: 300px !important;
  }
  .home_sec_01_left .red_leaf {
    left: 215px;
    top: 200px;
    z-index: -5;
  }
}
@media only screen and (max-width: 520px) {
  .home_sec_06 .service_card_img {
    height: 140px;
  }
  .blog_main_box_img {
    height: 170px;
  }
  .service_header_arrow {
    height: 123px;
  }
  .about_sec01 img {
    width: 80%;
  }
  .home_sec_07 .doted_squere {
    display: none;
  }
  .blog_main .doted_squere2 {
    top: 160px;
  }
}
@media only screen and (max-width: 420px) {
  .service_header_arrow {
    height: 100px;
  }
  header .main_logo img {
    width: 200px;
  }
  #carousel .carousel-item .caption {
    padding: 40px 20px;
  }
  .carousel-control-prev {
    left: -24px !important;
  }
  .carousel-control-next {
    right: -24px !important;
  }
  .defilee__div:not(.defilee__div--text) {
    width: 40vw;
    height: 40vw;
  }
  .testim .cont div .img .doted_squere {
    top: 5px !important;
    left: 20% !important;
  }
}
@media only screen and (max-width: 338px) {
  .home_sec_01_left .red_leaf {
    left: 215px;
    top: 280px;
    z-index: -5;
  }
}
